import { AppContext, AppInitialProps } from 'next/app'
import App from '../library/components/app'
import '../styles/application.scss'
import '../styles/styleguide.scss'
import React from 'react'
import { BrowserConfiguration, getBrowserConfiguration } from '../library/BrowserConfiguration'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { polyfillPromiseWithResolvers } from "../library/utils/polyfilsResolver";

import 'core-js/full/promise/with-resolvers.js';

polyfillPromiseWithResolvers();

type Props = AppContext & AppInitialProps
interface State { }

// <mounted> There were certain cases where we needed to know when the app was mounted. This allows us to know from *outside* React components.
let _mounted: boolean = false

export const isMounted = (): boolean => {
  return _mounted
}
// </mounted>

export default class extends React.Component<Props, State> {

  queryClient = new QueryClient()

  componentDidMount = (): void => {
    const config: BrowserConfiguration = getBrowserConfiguration()
    if (config.FORCE_HTTPS) {
      if (!window.location.href.startsWith('https')) {
        const redirect: string = window.location.href.replace('http://', 'https://')
        window.location.href = redirect
      }
    }

    _mounted = true
  }


  render = () => {
    return (
      <QueryClientProvider client={this.queryClient}>
        <App>
          <this.props.Component {...this.props.pageProps} />
        </App>
      </QueryClientProvider>
    )
  }
}
